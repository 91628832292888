import { Role } from "../../constants/data";

export const isRoleSuper = (state: any) => {
  return state.auth?.user !== undefined && state.auth?.user.role === Role.Super;
};

export const isRoleAdmin = (state: any) => {
  const role = state.auth?.user?.role;
  return role !== undefined && (role === Role.Super || role === Role.Admin);
};

export const isRoleEditor = (state: any) => {
  const role = state.auth?.user?.role;
  return (
    role !== undefined &&
    (role === Role.Super || role === Role.Admin || role === Role.Editor)
  );
};
