export const THAI_ENGLISH_NUMBER_SYMBOL =
  /^[a-zA-Z0-9?()":./,{}[\]/\-_+=!@#$%^&*|'\u0E01-\u0E3A\u0E40-\u0E4E\s]*$/;
export const URL_REGEX = /(https?:\/\/[^\s]+)/g;
export const BLOCK_SCRIPT_REGEX = /[<>]/g;

export enum UserDropdownKey {
  Edit = "แก้ไขข้อมูล",
  ChangePassword = "เปลี่ยนรหัสผ่าน",
  Delete = "ลบผู้ใช้งาน",
}

export const UserDropdown: UserDropdownKey[] = [
  UserDropdownKey.Edit,
  UserDropdownKey.ChangePassword,
  UserDropdownKey.Delete,
];

export const CreateUser = [
  { name: "สร้าง Link", link: "link" },
  { name: "กำหนดเอง", link: "manual" },
];

export enum QaCardDropdownKey {
  Delete = "ลบคำถาม",
  Edit = "แก้ไขคำถาม",
  Create = "เพิ่มคำถาม",
}

export const QaCardDropdown: QaCardDropdownKey[] = [
  QaCardDropdownKey.Edit,
  QaCardDropdownKey.Delete,
];

export enum CategoryDropdownKey {
  Create = "เพิ่มชื่อหัวข้อ",
  Edit = "เปลี่ยนชื่อหัวข้อ",
  Delete = "ลบหัวข้อ",
}

export const CategoryDropdown: CategoryDropdownKey[] = [
  CategoryDropdownKey.Edit,
  CategoryDropdownKey.Delete,
];

export enum Role {
  Super = "super",
  Admin = "admin",
  Editor = "editor",
  Viewer = "viewer",
}

export const RoleConstants = [
  { id: "super", value: "Super" },
  { id: "admin", value: "Admin" },
  { id: "editor", value: "Editor" },
  { id: "viewer", value: "Viewer" },
];
