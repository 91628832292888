import styled from "styled-components";

export const Input = styled.input`
  background-color: ${(props) => (props.disabled ? "#DDDDDD" : "#fff")};
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  background-color: ${(props) => (props.disabled ? "#DDDDDD" : "#fff")};
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
`;

export const InputWithOutBorder = styled.input`
  background-color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
`;

export const ContainerInputWithIcon = styled.div`
  background-color: #fff;
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 100%;
`;

export const OrangeInput = styled.input`
  background-color: ${(props) => (props.disabled ? "#DDDDDD" : "#fff")};
  border: 0px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #f37021;
  }
  :-ms-input-placeholder {
    color: #f37021;
  }

  &:focus {
    outline: none;
  }
`;
