import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ic_expand from "../assets/icons/ic_expand.svg";
import { OrangeButtonWithIcon, OrangeLeftIcon } from "./button/OrangeButton";
import ic_circle_add from "../assets/icons/ic_circle_add.svg";
import ic_three_dots_white from "../assets/icons/ic_three_dots_white.svg";
import {
  DropdownContainerInterface,
  DropdownItemInterface,
} from "../component/input/Dropdown";
import useOnClickOutside from "../utils/useOnClickOutside";
import { CategoryDropdown, CategoryDropdownKey } from "../constants/data";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/action/auth";
import CategoryModal from "./modal/CategoryModal";
import { ButtonMode } from "./button/Button";
import DeleteModal from "./modal/DeleteModal";
import {
  CategoryResponse,
  deleteCategory,
  getListCategory,
} from "../redux/services/category";
import {
  deleteCurrentCategory,
  selectCurrentCategory,
  setCurrentCategory,
} from "../redux/action/category";
import { getCurrentCategory, getSelectedCard } from "../redux/selectors/path";
import { useAlert } from "react-alert";
import { AlertText } from "../constants/alertText";
import { selectedSearchBar, setQaCardList } from "../redux/action/qa";
import { getListQa } from "../redux/services/qa";
import { isRoleAdmin } from "../redux/selectors/isRole";
import { initialCategory } from "../redux/reducers/categoryReducer";

interface ExpandIcon {
  isExpand: boolean;
}

const CategoryList = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentCategory = useSelector((state) => getCurrentCategory(state));
  const selectedCard = useSelector((state) => getSelectedCard(state));
  const isAdmin = useSelector((state) => isRoleAdmin(state));
  const [isExpand, setIsExpand] = useState(true);
  const [category, setCategory] = useState<CategoryResponse[]>([]);
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [modal, setModal] = useState<CategoryDropdownKey>();

  const handleClickChoice = (choice: CategoryDropdownKey) => {
    setShowDropdown(false);
    setModal(choice);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  useEffect(() => {
    dispatch(selectCurrentCategory(initialCategory));
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentCategory]);

  const fetchData = async () => {
    await fetchCategory(currentCategory.path);
    if (currentCategory.name !== "หน้าแรก")
      await fetchQaCard(currentCategory.id);
  };

  const fetchCategory = async (path: string) => {
    dispatch(setLoading(true));
    const response = await getListCategory(
      `parent=${path.replaceAll(" ", "%20")}`
    );

    if (typeof response === "string") {
      alert.error(response);
    } else {
      setCategory(response.results);
    }
    dispatch(setLoading(false));
  };

  const fetchQaCard = async (categoryId?: string) => {
    dispatch(setLoading(true));
    dispatch(selectedSearchBar(undefined));
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      let matched = -1;
      const result = response.results.map((item, index) => {
        if (selectedCard && selectedCard.id === item.id) {
          matched = index;
        }
        return {
          ...item,
          isExpand: selectedCard ? selectedCard.id === item.id : undefined,
        };
      });
      dispatch(setQaCardList(result));
      if (matched !== -1) {
        dispatch(setQaCardList([result[matched]]));
      } else {
        dispatch(
          setQaCardList(
            response.results.map((item) => ({ ...item, isExpand: false }))
          )
        );
      }
    }
    dispatch(setLoading(false));
  };

  const handleOnclickCategory = async (category: CategoryResponse) => {
    dispatch(selectedSearchBar(undefined));
    dispatch(setCurrentCategory(category));
  };

  const fetchAllQaCard = async (categoryId: string) => {
    dispatch(setLoading(true));
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      dispatch(
        setQaCardList(
          response.results.map((item) => ({ ...item, isExpand: false }))
        )
      );
      dispatch(selectedSearchBar(undefined));
    }
    dispatch(setLoading(false));
  };

  const handleDeleteCategory = async () => {
    dispatch(setLoading(true));
    const response = await deleteCategory(currentCategory.id);
    if (typeof response === "string" && response !== "") {
      alert.error(response);
    } else {
      alert.success(AlertText.deleteUserSuccess);
      dispatch(deleteCurrentCategory());
      setModal(undefined);
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      <HeaderContainer>
        <HeaderContainer>
          <HeaderText>{currentCategory.name}</HeaderText>
          {currentCategory.name !== "หน้าแรก" && isAdmin ? (
            <ThreeDotsContainer>
              <ThreeDotsStyled
                src={ic_three_dots_white}
                onClick={() => setShowDropdown(!showDropdown)}
              />
              <DropdownContainer showDropdown={showDropdown} ref={dropdownRef}>
                {CategoryDropdown.map((item, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleClickChoice(item)}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownContainer>
            </ThreeDotsContainer>
          ) : null}
        </HeaderContainer>
        <AddButtonContainer>
          {isAdmin ? (
            <div>
              <OrangeButtonWithIcon
                onClick={() => setModal(CategoryDropdownKey.Create)}
              >
                <OrangeLeftIcon src={ic_circle_add} />
                เพิ่มหัวข้อ
              </OrangeButtonWithIcon>
            </div>
          ) : null}
          <ExpandIcon
            src={ic_expand}
            onClick={() => setIsExpand(!isExpand)}
            isExpand={isExpand}
          />
        </AddButtonContainer>
      </HeaderContainer>
      <ExpandContainer isExpand={isExpand}>
        {category.length !== 0 ? (
          <OrangeBoxContainer>
            {category.map((item, index) => (
              <OrangeBox
                key={index}
                onClick={() => handleOnclickCategory(item)}
              >
                {item.name}
              </OrangeBox>
            ))}
          </OrangeBoxContainer>
        ) : (
          <EmptyStyled>ยังไม่มีหัวข้อที่จะแสดง</EmptyStyled>
        )}
      </ExpandContainer>
      <CategoryModal
        show={modal === CategoryDropdownKey.Edit}
        handleShow={(value: CategoryDropdownKey) => setModal(value)}
        editMode={true}
        trigger={() => fetchCategory(currentCategory.path)}
      />
      <CategoryModal
        show={modal === CategoryDropdownKey.Create}
        handleShow={(value: CategoryDropdownKey) => setModal(value)}
        editMode={false}
        trigger={() => fetchCategory(currentCategory.path)}
      />
      <DeleteModal
        header={CategoryDropdownKey.Delete}
        content={
          "การลบหัวข้อจะทำให้ข้อมูลข้างในหัวข้อ ทั้งหัวข้อภายในและคำถามข้างในทั้งหมดหายไปด้วย ยืนยันการลบหัวข้อใช่หรือไม่ ?"
        }
        rightButton={{ label: "ลบหัวข้อ", mode: ButtonMode.Primary }}
        leftButton={{ label: "ยกเลิก", mode: ButtonMode.Secondary }}
        show={modal === CategoryDropdownKey.Delete}
        handleShow={(value: CategoryDropdownKey) => setModal(value)}
        handleRightButton={() => handleDeleteCategory()}
      />
    </>
  );
};

export default CategoryList;

const OrangeBox = styled.div`
  background-color: #f37021;
  color: #fff;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
`;

const OrangeBoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100vw;

  @media (min-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const ExpandContainer = styled.div<ExpandIcon>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: ${(props) => (props.isExpand ? "100vh" : "0")};
  transition: 0.4s ease-in-out;
`;

const HeaderText = styled.label`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0 8px 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExpandIcon = styled.img<ExpandIcon>`
  margin-left: 10px;
  transition: transform 200ms;
  cursor: pointer;
  transform: rotate(${(props) => (props.isExpand ? "0" : "180deg")});
`;

const EmptyStyled = styled.label`
  color: #686868;
  font-size: 14px;
  line-height: 20px;
`;

const AddButtonContainer = styled.div`
  align-items: center;
  display: flex;
`;

const DropdownContainer = styled.div<DropdownContainerInterface>`
  display: ${(props) => (props.showDropdown ? "" : "none")};
  position: absolute;
  z-index: 9;
  width: 116px;
  background: rgba(104, 104, 104, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  padding: 4px 0 4px 0;
`;

const DropdownItem = styled.div<DropdownItemInterface>`
  padding: 8px 0px 8px 16px;
  white-space: nowrap;
  color: #fff;

  &:hover {
    color: #686868;
    background-color: #fff;
  }
`;

const ThreeDotsStyled = styled.img`
  cursor: pointer;
  color: #fff;
`;

const ThreeDotsContainer = styled.div`
  margin-left: 8px;
  align-self: center;
  position: relative;
`;
