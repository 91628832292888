import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Path } from "../constants/path";
import { useDispatch } from "react-redux";
import { SessionTimeout } from "../redux/action/auth";
import { useAlert } from "react-alert";
import { selectCurrentCategory } from "../redux/action/category";
import { setQaCardList } from "../redux/action/qa";
import { initialCategory } from "../redux/reducers/categoryReducer";
interface SidebarInterface {
  showSidebar: boolean;
}

interface SidebarProps {
  showSidebar: boolean;
  onClickItem: Function;
  isSuper: boolean;
}

const Sidebar = (props: SidebarProps) => {
  const { isSuper } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleOnClick = (path: string) => {
    history.push(path);
    props.onClickItem();

    if (path == Path.home) {
      dispatch(selectCurrentCategory(initialCategory));
      dispatch(setQaCardList([]));
    }
  };

  const handleLogoutButton = () => {
    dispatch(SessionTimeout());
    history.push(Path.login);
    alert.success("ลงชื่อออกสำเร็จ");
    props.onClickItem();
  };

  return (
    <OuterContainer showSidebar={props.showSidebar}>
      <Container>
        <Text onClick={() => handleOnClick(Path.home)}>หน้าแรก</Text>
        <Text onClick={() => handleOnClick(Path.resetPassword)}>
          เปลี่ยนรหัสผ่าน
        </Text>
        {isSuper ? (
          <Text onClick={() => handleOnClick(Path.userManagement)}>
            จัดการผู้ใช้งาน
          </Text>
        ) : null}
        <Text onClick={() => handleLogoutButton()}>ออกจากระบบ</Text>
      </Container>
    </OuterContainer>
  );
};

export default Sidebar;

const OuterContainer = styled.div<SidebarInterface>`
  position: fixed;
  top: 48px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  transition: transform 200ms;
  transform: ${(props) =>
    props.showSidebar ? `translate(0%)` : `translate(-100%)`};
`;

const Container = styled.div`
  display: flex;
  background-color: #000;
  flex-direction: column;
  padding: 40px 32px 40px 32px;
  height: 100%;
`;

const Text = styled.label`
  color: #fff;
  padding: 10px 0 10px 0;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #414042;
  border-radius: 1px;
`;
