import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setLoading } from "../redux/action/auth";
import { selectCurrentCategory } from "../redux/action/category";
import { selectedSearchBar, setQaCardList } from "../redux/action/qa";
import { getCurrentCategory, getCurrentPath } from "../redux/selectors/path";
import { CategoryResponse } from "../redux/services/category";
import { getListQa } from "../redux/services/qa";

const Breadcrumbs = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const currentPath = useSelector((state) => getCurrentPath(state));
  const currentCategory = useSelector((state) => getCurrentCategory(state));

  const fetchCategory = async (category: CategoryResponse) => {
    if (category.name === "หน้าแรก") dispatch(setQaCardList([]));
    if (currentCategory.id === category.id) {
      fetchQaCard(category.id);
    } else {
      dispatch(selectCurrentCategory(category));
    }
  };

  const fetchQaCard = async (categoryId: string) => {
    dispatch(setLoading(true));
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      dispatch(
        setQaCardList(
          response.results.map((item) => ({ ...item, isExpand: false }))
        )
      );
      dispatch(selectedSearchBar(undefined));
    }
    dispatch(setLoading(false));
  };

  return (
    <Container>
      {currentPath.map((item, index) => {
        return (
          <div key={index}>
            {index !== 0 && <label>{"> "}</label>}
            <LabelStyled
              style={{
                color: index === currentPath.length - 1 ? "#C1C1C1" : "",
              }}
              onClick={() => fetchCategory(item)}
            >
              {item.name}
            </LabelStyled>
          </div>
        );
      })}
    </Container>
  );
};

export default Breadcrumbs;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  font-size: 14px;
  color: #949494;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabelStyled = styled.label`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
