import styled from "styled-components";
import ic_role_active from "../../assets/icons/ic_role_active.svg";
import ic_close_gray from "../../assets/icons/ic_close_gray.svg";

interface RoleConfigModalProps {
  show: boolean;
  handleShow: Function;
}

interface ModalInterface {
  show: boolean;
}

const RoleConfigModal = (props: RoleConfigModalProps) => {
  const { show, handleShow } = props;

  const handleClose = () => {
    handleShow(false);
  };

  return (
    <ModalContainer show={show}>
      <Container>
        <HeaderContainer>
          <HeaderStyeld>สิทธิการเข้าถึง</HeaderStyeld>
          <CloseStyled src={ic_close_gray} onClick={() => handleClose()} />
        </HeaderContainer>
        <TableStyled>
          <tr>
            <AccesStyled></AccesStyled>
            <AccesStyled>จัดการผู้ใช้</AccesStyled>
            <AccesStyled>เพิ่ม/ลบหัวข้อ</AccesStyled>
            <AccesStyled>เพิ่ม/ลบคำถาม</AccesStyled>
            <AccesStyled>ดูคำถาม</AccesStyled>
          </tr>
          <IconRowStyled>
            <RoleStyled>Super</RoleStyled>
            <td>
              <img src={ic_role_active} />
            </td>
            <td>
              <img src={ic_role_active} />
            </td>
            <td>
              <img src={ic_role_active} />
            </td>
            <td>
              <img src={ic_role_active} />
            </td>
          </IconRowStyled>
          <IconRowStyled>
            <RoleStyled>Admin</RoleStyled>
            <td></td>
            <td>
              <img src={ic_role_active} />
            </td>
            <td>
              <img src={ic_role_active} />
            </td>
            <td>
              <img src={ic_role_active} />
            </td>
          </IconRowStyled>
          <IconRowStyled>
            <RoleStyled>Editor</RoleStyled>
            <td></td>
            <td></td>
            <td>
              <img src={ic_role_active} />
            </td>
            <td>
              <img src={ic_role_active} />
            </td>
          </IconRowStyled>
          <IconRowStyled>
            <RoleStyled>Viewer</RoleStyled>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <img src={ic_role_active} />
            </td>
          </IconRowStyled>
        </TableStyled>
      </Container>
    </ModalContainer>
  );
};

export default RoleConfigModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 25%;
  padding: 16px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (min-width: 767px) {
    width: 345px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 8px;
  color: #232323;
`;

const AccesStyled = styled.th`
  background: #dddddd;
  padding: 8px;
`;

const RoleStyled = styled.td`
  background: #dddddd;
  padding: 8px;
`;

const IconRowStyled = styled.tr`
  text-align: center;
`;

const TableStyled = styled.table`
  margin-top: 8px;
`;

const CloseStyled = styled.img`
  filter: contrast(0.5);
  height: 10px;
  cursor: pointer;
`;
