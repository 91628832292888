import styled from "styled-components";
import { AlertComponentPropsWithStyle } from "react-alert";
import ic_alert_error from "../assets/icons/ic_alert_error.svg";
import ic_alert_success from "../assets/icons/ic_alert_success.svg";
import ic_alert_warning from "../assets/icons/ic_alert_warning.svg";

enum AlertType {
  Info = "info",
  Success = "success",
  Error = "error",
}

interface AlertInterface {
  type: string;
}

const AlertTemplate = ({
  style,
  options,
  message,
  close,
}: AlertComponentPropsWithStyle) => {
  const type =
    options.type === AlertType.Error
      ? AlertType.Error
      : options.type === AlertType.Info
      ? AlertType.Info
      : AlertType.Success;

  const logo =
    options.type === AlertType.Error
      ? ic_alert_error
      : options.type === AlertType.Info
      ? ic_alert_warning
      : ic_alert_success;

  const Tag =
    options.type === AlertType.Error
      ? "Error"
      : options.type === AlertType.Info
      ? "Warning"
      : "Success";

  return (
    <Container>
      <TagAlert type={type}>
        <IconStyled src={logo} />
        <TagMessage>{Tag}</TagMessage>
      </TagAlert>
      <MessageContainer>
        <MessageStyled>{message}</MessageStyled>
      </MessageContainer>
      {/* {options.type === "info" && "!"} */}
    </Container>
  );
};

export default AlertTemplate;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #dddddd;
  margin: 20px;
  border-radius: 4px;
`;

const TagAlert = styled.div<AlertInterface>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.type === AlertType.Error && "#eb5757"}
    ${(props) => props.type === AlertType.Info && "#F2C94C"}
    ${(props) => props.type === AlertType.Success && "#27AE60"};
  border-radius: 4px 0px 0px 4px;
  padding: 8px;
`;

const TagMessage = styled.label`
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.28px;
  color: #ffffff;
`;

const MessageStyled = styled.label`
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #686868;
  padding: 8px;
  display: flex;
  align-items: center;
  white-space: break-spaces;
`;

const MessageContainer = styled.div`
  margin: 0 8px 0 8px;
  display: flex;
`;

const IconStyled = styled.img`
  margin-right: 8px;
`;
