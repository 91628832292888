import styled from "styled-components";
import { OrangeInput } from "../../component/input/Input";
import { FullButton } from "../../component/button/Button";
import { useSelector } from "react-redux";
import { getCurrentUsername } from "../../redux/selectors/getCurrentUser";
import { FormEvent, useState } from "react";
import { changePassword, ChangePasswordBody } from "../../redux/services/auth";
import { BLOCK_SCRIPT_REGEX } from "../../constants/data";
import { useAlert } from "react-alert";

const defaultForm = {
  oldPassword: "",
  newPassword: "",
};

const ResetPassword = () => {
  const alert = useAlert();
  const username = useSelector((state) => getCurrentUsername(state));
  const [form, setForm] = useState<ChangePasswordBody>(defaultForm);
  const [rePassword, setRePassword] = useState<string>("");

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.newPassword !== rePassword) {
      setForm({ ...form, newPassword: "" });
      setRePassword("");
      return alert.error("รหัสผ่านใหม่ไม่ตรงกัน");
    }

    const response = await changePassword(form);
    if (typeof response === "string" && response !== "") {
      alert.error(response);
    } else {
      alert.success("เปลี่ยนรหัสผ่านสำเร็จ");
    }
    setForm(defaultForm);
    setRePassword("");
  };

  return (
    <div>
      <ContentContainter onSubmit={handleSubmit}>
        <InputContainter>
          <OrangeInput disabled={true} value={username} />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            required
            type="password"
            placeholder="รหัสผ่านเก่า"
            name="oldPassword"
            value={form.oldPassword}
            onChange={handleOnChangeInput}
          />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            required
            type="password"
            placeholder="รหัสผ่านใหม่"
            name="newPassword"
            value={form.newPassword}
            onChange={handleOnChangeInput}
          />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            required
            type="password"
            placeholder="รหัสผ่านใหม่อีกครั้ง"
            name="rePassword"
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
          />
        </InputContainter>
        <InputContainter>
          <FullButton type="submit">เปลี่ยนรหัสผ่าน</FullButton>
        </InputContainter>
      </ContentContainter>
    </div>
  );
};

export default ResetPassword;

const InputContainter = styled.div`
  margin-bottom: 16px;
`;

const ContentContainter = styled.form`
  padding: 0 40px 0 40px;
`;

const Logo = styled.img`
  margin: 0 0 64px 0;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
