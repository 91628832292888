import { TokenResponse, UserResponse } from "../../constants/responseTypes";
import { ActionWithPayload } from "../../constants/types";
import * as Types from "../constants/types";
export interface AuthPayload {
  token?: TokenResponse;
  loading?: boolean;
  user?: UserResponse;
}

export const authReducer = (
  state = {
    token: undefined,
    loading: false,
  },
  action: ActionWithPayload<AuthPayload>
): AuthPayload => {
  switch (action.type) {
    case Types.AUTH_SUCCESS:
      return {
        ...state,
        token: action.payload?.token,
        user: action.payload?.user,
      };
    case Types.SESSION_TIMEOUT:
      return {
        ...state,
        token: undefined,
        user: undefined,
        loading: false,
      };
    case Types.LOADING:
      return {
        ...state,
        loading: action.payload?.loading,
      };
  }
  return state;
};
