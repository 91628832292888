import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "../input/Input";
import { Button } from "../button/Button";
import { ModalInterface, Trigger } from "../../constants/types";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  CreateCategoryRequest,
  updateCategory,
} from "../../redux/services/category";
import { useAlert } from "react-alert";
import { AlertText } from "../../constants/alertText";
import { getCurrentCategory } from "../../redux/selectors/path";
import { updateCurrentCategory } from "../../redux/action/category";
import { BLOCK_SCRIPT_REGEX } from "../../constants/data";

interface CategoryModalInterface {
  show: boolean;
  handleShow: Function;
  editMode: boolean;
}

const defaultForm = {
  name: "",
  path: "",
};

const CategoryModal = (props: CategoryModalInterface & Trigger) => {
  const { show, handleShow, editMode, trigger } = props;
  const dispatch = useDispatch();
  const currentCategory = useSelector((state) => getCurrentCategory(state));
  const alert = useAlert();
  const [form, setForm] = useState<CreateCategoryRequest>(defaultForm);

  useEffect(() => {
    setForm(editMode ? { name: currentCategory.name } : defaultForm);
  }, [show]);

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCloseButton = () => {
    handleShow(!show);
  };

  const handleSubmitButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const currentPath =
      currentCategory.path === "/"
        ? `${currentCategory.path}${form.name}`
        : `${currentCategory.path}/${form.name}`;

    const response = await createCategory({
      ...form,
      path: currentPath,
    });
    if (typeof response === "string") {
      alert.error(response);
    } else {
      handleShow(false);
      alert.success(AlertText.createCategorySuccess);
      trigger();
    }
  };

  const handleEditButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await updateCategory(currentCategory.id, form);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      dispatch(updateCurrentCategory(response));
      handleShow(false);
      alert.success(AlertText.updateCategorySuccess);
    }
  };

  return (
    <ModalContainer show={show}>
      <Container onSubmit={editMode ? handleEditButton : handleSubmitButton}>
        <HeaderStyeld>
          {editMode ? "เปลี่ยนชื่อหัวข้อ" : "เพิ่มชื่อหัวข้อ"}
        </HeaderStyeld>
        <InputContainer>
          <CategoryLabel>ชื่อหัวข้อ</CategoryLabel>
          <Input
            required
            name="name"
            value={form.name}
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            mode="cancel"
            type="button"
            onClick={() => handleCloseButton()}
          >
            ยกเลิก
          </Button>
          <Button mode="primary" type="submit">
            บันทึก
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

export default CategoryModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.form`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 35%;
  padding: 16px;
  border: 1px solid #888;
  width: 70%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (min-width: 767px) {
    width: 345px;
  }
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
`;

const CategoryLabel = styled.label`
  font-size: 14px;
  line-height: 34px;
  color: #949494;
  white-space: nowrap;
  margin-right: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 8px 0 8px 0;
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;
