import { ActionWithPayload } from "../../constants/types";
import * as Types from "../constants/types";
import { QaResponse } from "../services/qa";

export interface QaPayload {
  qaList: QaResponse[];
  qa: QaResponse;
}

export const qaReducer = (
  state = { qaList: [], lastestQa: undefined, selected: undefined },
  action: ActionWithPayload<QaPayload>
) => {
  switch (action.type) {
    case Types.FETCH_QA_CARD:
      return {
        ...state,
        qaList: action.payload?.qaList,
      };
    case Types.CREATE_QA_CARD:
      return {
        ...state,
        qaList: [...state.qaList, action.payload?.qa],
        lastestQa: action.payload?.qa,
      };
    case Types.DELETE_QA_CARD:
      return {
        ...state,
        lastestQa: undefined,
      };
    case Types.UPDATE_QA_CARD:
      return {
        ...state,
        lastestQa: action.payload?.qa,
      };
    case Types.SELECTED_SEARCH_BAR:
      return {
        ...state,
        selected: action.payload?.qa,
      };
  }
  return state;
};
