import * as Types from "../constants/types";
import { QaResponse } from "../services/qa";

export const setQaCardList = (qaList: QaResponse[]) => ({
  type: Types.FETCH_QA_CARD,
  payload: {
    qaList,
  },
});

export const createQaCard = (qa: QaResponse) => ({
  type: Types.CREATE_QA_CARD,
  payload: {
    qa,
  },
});

export const deleteQaCard = () => ({
  type: Types.DELETE_QA_CARD,
});

export const updateQaCard = (qa: QaResponse) => ({
  type: Types.CREATE_QA_CARD,
  payload: {
    qa,
  },
});

export const selectedSearchBar = (qa?: QaResponse) => ({
  type: Types.SELECTED_SEARCH_BAR,
  payload: {
    qa,
  },
});
