import axios, { AxiosResponse } from "axios";
import { PaginationProps } from "../../constants/types";
import { getConfig, handleErrorResponse } from "./auth";

const API_VERSION = "v1";
const SECTION = "category";
const BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL}/${API_VERSION}/${SECTION}`;
const LIMIT_PER_PAGE = "&limit=200";

export interface CreateCategoryRequest {
  name?: string;
  path?: string;
}

export interface CategoryResponse {
  name: string;
  path: string;
  parent: string;
  id: string;
}

export interface GetCategoryResponse extends PaginationProps {
  results: CategoryResponse[];
}

export const createCategory = async (form: CreateCategoryRequest) => {
  try {
    const response = await axios.post<
      CreateCategoryRequest,
      AxiosResponse<CategoryResponse>
    >(`${BACKEND_URL}`, form, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const deleteCategory = async (id: string) => {
  try {
    const response = await axios.delete(`${BACKEND_URL}/${id}`, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getListCategory = async (path?: string) => {
  const url = path
    ? `${BACKEND_URL}?${path}${LIMIT_PER_PAGE}`
    : `${BACKEND_URL}${LIMIT_PER_PAGE}`;

  try {
    const response = await axios.get<GetCategoryResponse>(url, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const updateCategory = async (
  id: string,
  form: CreateCategoryRequest
) => {
  try {
    const response = await axios.patch<
      CreateCategoryRequest,
      AxiosResponse<CategoryResponse>
    >(`${BACKEND_URL}/${id}`, form, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getCategoryById = async (id: string) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/${id}`, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};
