import { useState } from "react";
import styled from "styled-components";
import { InputWithOutBorder, ContainerInputWithIcon } from "../input/Input";
import { Button } from "../button/Button";
import Dropdown from "../input/Dropdown";
import ic_copy_black from "../../assets/icons/ic_copy_black.svg";
import { createRegisterToken, RoleBody } from "../../redux/services/auth";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RoleConstants } from "../../constants/data";
import { Trigger } from "../../constants/types";

interface NewUserUrlModalInterface {
  show: boolean;
  handleShow: Function;
}

interface ModalInterface {
  show: boolean;
}

const defaultValue = {
  role: "",
};

const NewUserUrlModal = (props: NewUserUrlModalInterface & Trigger) => {
  const { show, handleShow, trigger } = props;
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [form, setForm] = useState<RoleBody>(defaultValue);

  const handleCreateUrl = async () => {
    const response = await createRegisterToken(form);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      setUrl(response.id);
      alert.success("สร้างลิงก์สมัครสำเร็จ");
      trigger();
    }
  };

  const handleDropdown = (value: string) => {
    setForm({ ...form, role: value });
    setUrl("");
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/register?registerToken=${url}`
    );
    alert.success("คัดลอกลิงก์สมัคร");
  };

  return (
    <ModalContainer show={show}>
      <Container>
        <HeaderStyeld>เพิ่มผู้ใช้งานใหม่</HeaderStyeld>
        <InputContainer>
          <InputRow>
            <CategoryLabel>Role</CategoryLabel>
            <Dropdown
              list={RoleConstants}
              onChange={(value: string) => handleDropdown(value)}
            />
          </InputRow>
          <InputRow>
            <CategoryLabel>Link</CategoryLabel>
            <ContainerInputWithIcon>
              <InputWithOutBorder
                disabled
                value={
                  url
                    ? `${process.env.REACT_APP_BASE_URL}/register?registerToken=${url}`
                    : ""
                }
              />
              <CopyIcon src={ic_copy_black} onClick={() => handleCopyUrl()} />
            </ContainerInputWithIcon>
          </InputRow>
          <InputRow>
            <CategoryLabel>หมดอายุใน</CategoryLabel>
            <TimeLabel>15:00 นาที</TimeLabel>
          </InputRow>
        </InputContainer>
        <ButtonContainer>
          <Button
            mode="cancel"
            onClick={() => {
              handleShow(!show);
              setUrl("");
            }}
          >
            ยกเลิก
          </Button>
          <Button mode="primary" onClick={() => handleCreateUrl()}>
            สร้าง Link
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

export default NewUserUrlModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 30%;
  padding: 16px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (min-width: 767px) {
    width: 345px;
  }
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
`;

const CategoryLabel = styled.label`
  font-size: 14px;
  line-height: 34px;
  color: #949494;
  white-space: nowrap;
  margin-right: 10px;
  width: 120px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
`;

const InputRow = styled.div`
  display: inline-flex;
  margin: 0 0 8px 0;
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const TimeLabel = styled.label`
  align-self: center;
  width: 100%;
  color: #c1c1c1;
  font-weight: 600;
  font-size: 16px;
  line-height: 34px;
`;

const CopyIcon = styled.img`
  padding: 0 10px;
`;
