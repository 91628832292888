export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const LOADING = "LOADING";
export const SESSION_TIMEOUT = "SESSION_TIMEOUT";

export const SET_CURRENT_PATH = "SET_CURRENT_PATH";
export const SELECT_CURRENT_CATEGORY = "SELECT_CURRENT_CATEGORY";
export const DELETE_CURRENT_CATEGORY = "DELETE_CURRENT_CATEGORY";
export const UPDATE_CURRENT_CATEGORY = "UPDATE_CURRENT_CATEGORY";

export const FETCH_QA_CARD = "FETCH_QA_CARD";
export const CREATE_QA_CARD = "CREATE_QA_CARD";
export const DELETE_QA_CARD = "DELETE_QA_CARD";
export const UPDATE_QA_CARD = "UPDATE_QA_CARD";
export const SELECTED_SEARCH_BAR = "SELECTED_SEARCH_BAR";
