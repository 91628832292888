import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ic_notfound from "../assets/icons/ic_notfound.svg";
import { FullButton } from "../component/button/Button";
import { Path } from "../constants/path";

const NotFound = () => {
  const history = useHistory();
  const handleClick = () => history.push(Path.home);

  return (
    <Container>
      <ImageContainer src={ic_notfound} />
      <ContentContainer>
        <TextStyled>
          ดูเหมือนคุณหลงทางแล้วล่ะ
          <br />
          เรากลับไปหน้าแรกด้วยกันนะ
        </TextStyled>
        <FullButton onClick={() => handleClick()}>กลับหน้าแรก</FullButton>
      </ContentContainer>
    </Container>
  );
};

export default NotFound;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 64px 40px 0 40px;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TextStyled = styled.label`
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.28px;
  margin: 24px 0 8px 0;
  color: #dddddd;
`;

const ImageContainer = styled.img`
  @media (min-width: 768px) {
    width: 500px;
  }
`;
