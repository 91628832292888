import { CategoryResponse } from "../services/category";
import * as Types from "../constants/types";

export const setCurrentCategory = (current: CategoryResponse) => ({
  type: Types.SET_CURRENT_PATH,
  payload: {
    current,
  },
});

export const selectCurrentCategory = (current: CategoryResponse) => ({
  type: Types.SELECT_CURRENT_CATEGORY,
  payload: {
    current,
  },
});

export const deleteCurrentCategory = () => ({
  type: Types.DELETE_CURRENT_CATEGORY,
});

export const updateCurrentCategory = (current: CategoryResponse) => ({
  type: Types.UPDATE_CURRENT_CATEGORY,
  payload: {
    current,
  },
});
