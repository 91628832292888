import axios, { AxiosResponse } from "axios";
import { UpdateUserBody } from "../../component/modal/EditUserModal";
import {
  ErrorResponse,
  ListUserResponse,
  RegisterTokenResponse,
  UserResponse,
  UserWithTokenResponse,
} from "../../constants/responseTypes";
import { store } from "../../store";
import { AuthSuccess, SessionTimeout } from "../action/auth";
import { AuthPayload } from "../reducers/authReducer";

const API_VERSION = "v1";
const BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL}/${API_VERSION}`;

export type LoginBody = {
  username: string;
  password: string;
};

type LogoutBody = {
  refreshToken: string;
};

export type ChangePasswordBody = {
  oldPassword?: string;
  newPassword?: string;
};

export type RoleBody = {
  role: string;
};

export type UserBody = LoginBody & RoleBody;

export const handleErrorResponse = (error: any) => {
  const errorResponse: ErrorResponse = error.response.data;
  if (errorResponse.code === 401) store.dispatch(SessionTimeout());
  return errorResponse.message;
};

export const register = async (token: string, form: LoginBody) => {
  try {
    const response = await axios.post<
      LoginBody,
      AxiosResponse<UserWithTokenResponse>
    >(`${BACKEND_URL}/auth/register?regTokenId=${token}`, form);
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const login = async (form: LoginBody) => {
  try {
    const response = await axios.post<
      LoginBody,
      AxiosResponse<UserWithTokenResponse>
    >(`${BACKEND_URL}/auth/login`, form);
    store.dispatch(AuthSuccess(response.data.tokens, response.data.user));
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const logout = async (form: LogoutBody) => {
  const { data } = await axios.post(`${BACKEND_URL}/auth/logout`, form);
  // TODO:
};

export const changePassword = async (form: ChangePasswordBody) => {
  try {
    const response = await axios.post<ChangePasswordBody>(
      `${BACKEND_URL}/auth/changePassword`,
      form,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const createRegisterToken = async (form: RoleBody) => {
  try {
    const response = await axios.post<
      RoleBody,
      AxiosResponse<RegisterTokenResponse>
    >(`${BACKEND_URL}/regtoken`, form, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const createUser = async (form: UserBody) => {
  try {
    const response = await axios.post<UserBody, AxiosResponse<UserResponse>>(
      `${BACKEND_URL}/users`,
      form,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const updateUser = async (id: string, form: UpdateUserBody) => {
  try {
    const response = await axios.patch<
      UpdateUserBody,
      AxiosResponse<UserResponse>
    >(`${BACKEND_URL}/users/${id}`, form, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const deleteUser = async (id: string) => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL}/users/${id}`,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getUser = async () => {
  try {
    const response = await axios.get<ListUserResponse>(
      `${BACKEND_URL}/users`,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getUserById = async (id: string) => {
  try {
    const response = await axios.get<UserResponse>(
      `${BACKEND_URL}/users/${id}`,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getUnusedUser = async () => {
  try {
    const response = await axios.get<RegisterTokenResponse[]>(
      `${BACKEND_URL}/regtoken`,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const deleteUnusedUser = async (id: string) => {
  try {
    const response = await axios.delete(
      `${BACKEND_URL}/regtoken/${id}`,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getConfig = () => {
  // Get token from state
  const token = store.getState().auth as AuthPayload;
  // Headers
  const config: any = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (token.token) {
    config.headers["Authorization"] = `Bearer ${token.token?.access.token}`;
  }

  return config;
};
