import styled from "styled-components";
import { Button, ButtonMode } from "../button/Button";
import { ModalInterface } from "../../constants/types";

interface ButtonInterface {
  label: string;
  mode: ButtonMode;
}
interface DeleteModalInterface {
  header: string;
  content: string;
  rightButton: ButtonInterface;
  leftButton: ButtonInterface;
  show: boolean;
  handleShow: Function;
  handleLeftButton?: () => void;
  handleRightButton?: () => void;
}

const DeleteModal = (props: DeleteModalInterface) => {
  const {
    header,
    content,
    rightButton,
    leftButton,
    show,
    handleShow,
    handleLeftButton,
    handleRightButton,
  } = props;

  return (
    <ModalContainer show={show}>
      <Container>
        <HeaderStyeld>{header}</HeaderStyeld>
        <ContentContainer>
          <CategoryLabel>{content}</CategoryLabel>
        </ContentContainer>
        <ButtonContainer>
          <Button mode={leftButton.mode} onClick={() => handleShow(!show)}>
            {leftButton.label}
          </Button>
          <Button mode={rightButton.mode} onClick={handleRightButton}>
            {rightButton.label}
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

export default DeleteModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 35%;
  padding: 16px;
  border: 1px solid #888;
  width: 265px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
  text-align: center;
`;

const CategoryLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  color: #949494;
  text-align: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 8px 0;
`;

const ButtonContainer = styled.div`
  justify-content: center;
  display: flex;
`;
