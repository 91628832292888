import { useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ic_down from "../../assets/icons/ic_down.svg";
import ic_question from "../../assets/icons/ic_question.svg";
import { BLOCK_SCRIPT_REGEX } from "../../constants/data";
import { setLoading } from "../../redux/action/auth";
import {
  selectCurrentCategory,
  setCurrentCategory,
} from "../../redux/action/category";
import { selectedSearchBar, setQaCardList } from "../../redux/action/qa";
import {
  getCurrentCategory,
  getSelectedCard,
} from "../../redux/selectors/path";
import {
  getListQa,
  QaResponse,
  SearchBarResponse,
  SearchChoice,
  searchQa,
} from "../../redux/services/qa";
import useOnClickOutside from "../../utils/useOnClickOutside";

interface MainSearchDropdownInterface {
  placeholder: string;
}

interface DropdownContainerInterface {
  showDropdown: boolean;
}

interface DropdownItemInterface {
  selected: boolean;
}

interface SearchOptionsProps {
  key: SearchChoice;
  name: string;
}
const SearchOptions: SearchOptionsProps[] = [
  { key: SearchChoice.QuestionAndAnswer, name: "คำถาม-ตอบ" },
  { key: SearchChoice.QuestionOnly, name: "คำถาม" },
  { key: SearchChoice.AnswerOnly, name: "ตอบ" },
];

interface SearchBarProps {
  [key: string]: SearchBarResponse[];
}

const MainSearchDropdown = (props: MainSearchDropdownInterface) => {
  const { placeholder } = props;
  const dropdownRef = useRef(null);
  const searchContentRef = useRef(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const [choice, setChoice] = useState(SearchOptions[0]);
  const currentCategory = useSelector((state) => getCurrentCategory(state));
  const selectedCard = useSelector((state) => getSelectedCard(state));
  const [searchBar, setSearchBar] = useState<SearchBarProps>();
  const [showSearchContent, setShowSearchContent] = useState(false);

  const handleClickChoice = (choice: SearchOptionsProps) => {
    setChoice(choice);
    setShowDropdown(false);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  const handleClickOutsideSearchContent = () => {
    setShowSearchContent(false);
    setSearchtext("");
  };

  useOnClickOutside(dropdownRef, handleClickOutside);
  useOnClickOutside(searchContentRef, handleClickOutsideSearchContent);

  const handleOnChangeInput = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value)) {
      setSearchtext(e.target.value);
      // dispatch(setLoading(true));
      if (e.target.value !== "") {
        setShowSearchContent(true);
        const response = await searchQa(
          currentCategory.name !== "หน้าแรก" ? currentCategory.id : undefined,
          choice.key,
          e.target.value
        );
        if (typeof response === "string") {
          // alert.error(response);
          setSearchBar(undefined);
        } else {
          let group = response.reduce((r: any, a: any) => {
            r[a.category.name] = [...(r[a.category.name] || []), a];
            return r;
          }, {});
          setSearchBar(group);
        }
      } else {
        setShowSearchContent(false);
        if (currentCategory.name !== "หน้าแรก") {
          fetchQaCard(currentCategory.id);
        }
      }
      // dispatch(setLoading(false));
    }
  };

  const fetchQaCard = async (categoryId?: string) => {
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      dispatch(setQaCardList(response.results));
    }
  };

  const fetchSearchCard = async (categoryId: string, newQa: QaResponse) => {
    dispatch(setLoading(true));
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      let matched = -1;
      const result = response.results.map((item, index) => {
        if (newQa.id === item.id) {
          matched = index;
        }
        return {
          ...item,
          isExpand: newQa ? newQa.id === item.id : undefined,
        };
      });
      dispatch(setQaCardList(result));
      if (matched !== -1) {
        dispatch(setQaCardList([result[matched]]));
      } else {
        dispatch(
          setQaCardList(
            response.results.map((item) => ({ ...item, isExpand: false }))
          )
        );
      }
    }
    dispatch(setLoading(false));
  };

  const replaceSearchTextColor = (text: string) => {
    return text.replace(
      searchText,
      `<label style="color: #F37021; font-weight: bold;"> ${searchText} </label>`
    );
  };

  const handleOnClickSearch = (qa: SearchBarResponse) => {
    const newQa = { ...qa, category: qa.category.id };
    // TODO:
    fetchSearchCard(qa.category.id, newQa);
    dispatch(selectedSearchBar(newQa));
    setSearchtext("");
    setShowSearchContent(false);
  };

  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  return (
    <div style={{ position: "relative" }}>
      <div>
        <InputStyled
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => handleOnChangeInput(e)}
        ></InputStyled>
        <SearchBarContainer
          searchText={searchText !== "" && showSearchContent}
          ref={searchContentRef}
        >
          {searchBar ? (
            <div>
              {Object.keys(searchBar).map((item, index) => (
                <div>
                  <SearchHeaderCategoryContainer>
                    <label>ผลการค้นหาในหัวข้อ&nbsp;</label>
                    <label style={{ fontWeight: "bold" }}>{item}</label>
                  </SearchHeaderCategoryContainer>
                  {searchBar[item].map((qa: SearchBarResponse) => (
                    <SearchQuestionContainer
                      onClick={() => handleOnClickSearch(qa)}
                    >
                      <QuestionIcon src={ic_question} />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceSearchTextColor(qa.question),
                        }}
                      />
                    </SearchQuestionContainer>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <SearchHeaderCategoryContainer>
              <div
                dangerouslySetInnerHTML={{
                  __html: replaceSearchTextColor(
                    `ไม่พบ${searchText}ที่คุณกำลังค้นหา`
                  ),
                }}
              />
            </SearchHeaderCategoryContainer>
          )}
        </SearchBarContainer>
      </div>
      <ButtonDropdown>
        <ButtonStyled onClick={() => setShowDropdown(!showDropdown)}>
          {choice.name}
          <Icon src={ic_down}></Icon>
        </ButtonStyled>
        <DropdownContainer showDropdown={showDropdown} ref={dropdownRef}>
          {SearchOptions.map((item, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleClickChoice(item)}
              selected={choice === item}
            >
              {item.name}
            </DropdownItem>
          ))}
        </DropdownContainer>
      </ButtonDropdown>
    </div>
  );
};

export default MainSearchDropdown;

const InputStyled = styled.input`
  padding: 8px 16px 8px 16px;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #c1c1c1;
  }
  :-ms-input-placeholder {
    color: #c1c1c1;
  }
`;

const ButtonDropdown = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ButtonStyled = styled.button`
  width: 120px;
  border: none;
  padding: 10px 16px 8px 16px;
  border-radius: 0px 4px 4px 0px !important;
  background: #686868;
  color: #fff;
  cursor: pointer;
`;

const Icon = styled.img`
  padding: 0 0 0 2px;
`;

const DropdownContainer = styled.div<DropdownContainerInterface>`
  position: absolute;
  right: 0;
  width: 120px;
  z-index: 9;
  background: rgba(104, 104, 104, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  padding: 4px 0 4px 0;

  display: ${(props) => (props.showDropdown ? "" : "none")};
`;

const DropdownItem = styled.div<DropdownItemInterface>`
  padding: 8px 0px 8px 16px;
  white-space: nowrap;
  color: ${(props) => (props.selected ? "#686868" : "#fff")};
  background-color: ${(props) => (props.selected ? "#fff" : "#686868")};

  &:hover {
    color: #686868;
    background-color: #fff;
  }
`;

interface SearchBarContainerProps {
  searchText: boolean;
}

const SearchBarContainer = styled.div<SearchBarContainerProps>`
  display: ${(props) => (props.searchText ? "block" : "none")};
  position: absolute;
  left: 0;
  z-index: 9;
  background: #fff;
  width: calc(100% - 120px);
  border-radius: 4px;
  margin-top: 2px;
  max-height: 500px;
  overflow: auto;
`;

const SearchHeaderCategoryContainer = styled.div`
  padding: 8px;
  border-top: solid 1px #ddd;
  background: #f370212b;
`;

const SearchQuestionContainer = styled.div`
  padding: 8px;
  display: flex;
  border-top: solid 1px #ddd;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const QuestionIcon = styled.img`
  margin-right: 4px;
  cursor: pointer;
`;
// ${(props) =>
//     props.selected ? "color: #686868; background-color: #fff;" : "color: #fff;"}
