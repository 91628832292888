import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Path } from "./constants/path";
import getToken from "./redux/selectors/getToken";
import Header from "./component/Header";
import Sidebar from "./component/Sidebar";
import FirstPage from "./routes/FirstPage";
import Login from "./routes/auth/Login";
import NotFound from "./routes/NotFound";
import UserManagement from "./routes/UserManagement";
import Register from "./routes/auth/Register";
import ResetPassword from "./routes/auth/ResetPassword";
import ProtectedRoute from "./routes/PrivateRoute";
import LoadingModal from "./component/modal/LoadingModal";
import getLoadingStatus from "./redux/selectors/getLoadingStatus";
import { setLoading } from "./redux/action/auth";
import { isRoleSuper } from "./redux/selectors/isRole";

const App = () => {
  const dispatch = useDispatch();
  const [showSidebar, setShowSideBar] = useState(false);
  const hasToken = useSelector((state) => getToken(state));
  const isLoading = useSelector((state) => getLoadingStatus(state));
  const isSuper = useSelector((state) => isRoleSuper(state));

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  return (
    <BrowserRouter>
      {hasToken && (
        <>
          <Header
            isSuper={isSuper}
            showSidebar={showSidebar}
            onClickHamburger={() => setShowSideBar(!showSidebar)}
          />
          <Sidebar
            isSuper={isSuper}
            showSidebar={showSidebar}
            onClickItem={() => setShowSideBar(!showSidebar)}
          />
        </>
      )}
      <Container>
        <Switch>
          {/* normal route */}
          <Redirect exact from={Path.root} to={Path.login} />
          <Route path={Path.login} component={Login} />
          <Route path={Path.register} component={Register} />
          {/* private route: require auth */}
          <ProtectedRoute
            isAuthenticated={hasToken}
            authenticationPath={Path.login}
            path={Path.home}
            component={FirstPage}
          />
          <ProtectedRoute
            isAuthenticated={hasToken}
            authenticationPath={Path.login}
            path={Path.resetPassword}
            component={ResetPassword}
          />
          <ProtectedRoute
            isAuthenticated={hasToken && isSuper}
            authenticationPath={
              hasToken ? (isSuper ? "" : Path.home) : Path.login
            }
            path={Path.userManagement}
            component={UserManagement}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Container>
      <LoadingModal show={isLoading}></LoadingModal>
    </BrowserRouter>
  );
};

export default App;

const Container = styled.div`
  margin: 48px auto 0 auto;
  padding 16px 20px 16px 20px;
  max-width: 1024px;

  @media (min-width: 768px) {
    padding 32px 56px 32px 56px;
  }
`;
