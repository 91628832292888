import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ic_close from "../assets/icons/ic_close.svg";
import ic_logo from "../assets/icons/ic_logo.svg";
import ic_hamburger from "../assets/icons/ic_hamburger.svg";
import { Path } from "../constants/path";
import { useDispatch } from "react-redux";
import { SessionTimeout } from "../redux/action/auth";
import { useAlert } from "react-alert";
import { selectCurrentCategory } from "../redux/action/category";
import { initialCategory } from "../redux/reducers/categoryReducer";
import { setQaCardList } from "../redux/action/qa";

interface HeaderProps {
  showSidebar: boolean;
  onClickHamburger: Function;
  isSuper: boolean;
}

const Header = (props: HeaderProps) => {
  const { isSuper } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleLogoutButton = () => {
    dispatch(SessionTimeout());
    history.push(Path.login);
    alert.success("ลงชื่อออกสำเร็จ");
  };

  return (
    <OuterContainer>
      <Container>
        {props.showSidebar ? (
          <Hamburger src={ic_close} onClick={() => props.onClickHamburger()} />
        ) : (
          <Hamburger
            src={ic_hamburger}
            onClick={() => props.onClickHamburger()}
          />
        )}
        <img src={ic_logo} />
        <MenuContainer>
          <MenuText
            onClick={() => {
              history.push(Path.home);
              dispatch(selectCurrentCategory(initialCategory));
              dispatch(setQaCardList([]));
            }}
          >
            หน้าแรก
          </MenuText>
          <MenuText onClick={() => history.push(Path.resetPassword)}>
            เปลี่ยนรหัสผ่าน
          </MenuText>
          {isSuper ? (
            <MenuText onClick={() => history.push(Path.userManagement)}>
              จัดการผู้ใช้งาน
            </MenuText>
          ) : null}
          <MenuText onClick={() => handleLogoutButton()}>ออกจากระบบ</MenuText>
        </MenuContainer>
      </Container>
    </OuterContainer>
  );
};

export default Header;

const OuterContainer = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;
`;

const Container = styled.div`
  display: flex;
  background-color: #000;
  padding: 10px 20px 10px 20px;
  justify-content: space-between;
  align-items: center;
`;

const Hamburger = styled.img`
  @media (min-width: 768px) {
    display: none;
  }
`;

const MenuContainer = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

const MenuText = styled.label`
  color: #fff;
  padding: 14px 15px 14px 15px;
  white-space: nowrap;
  cursor: pointer;
`;
