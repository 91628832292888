import styled from "styled-components";
import { ModalInterface } from "../../constants/types";

interface LoadingModalProps {
  show: boolean;
}

const LoadingModal = (props: LoadingModalProps) => {
  const { show } = props;
  return (
    <ModalContainer show={show}>
      <Container>
        <HeaderStyeld>กำลังประมวลผล...</HeaderStyeld>
        <Loader />
      </Container>
    </ModalContainer>
  );
};

export default LoadingModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 35%;
  padding: 16px;
  border: 1px solid #888;
  width: 265px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
  text-align: center;
  margin-bottom: 20px;
`;

const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid #f37021;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
