import React, { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "../input/Input";
import { Button } from "../button/Button";
import Dropdown from "../input/Dropdown";
import { ModalInterface } from "../../constants/types";
import { RoleConstants, BLOCK_SCRIPT_REGEX } from "../../constants/data";

interface EditUserModalInterface {
  show: boolean;
  handleShow: Function;
  user: UpdateUserBody;
  handleSubmit: (user: UpdateUserBody) => void;
}

export interface UpdateUserBody {
  username?: string;
  role?: string;
  password?: string;
}

export const DefaultValueUpdateUserBody = {
  username: undefined,
  role: undefined,
  password: undefined,
};

const EditUserModal = (props: EditUserModalInterface) => {
  const { show, handleShow, user, handleSubmit } = props;
  const [form, setForm] = useState<UpdateUserBody>(DefaultValueUpdateUserBody);

  useEffect(() => {
    setForm(user);
  }, []);

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmitButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(form);
  };

  return (
    <ModalContainer show={show}>
      <Container onSubmit={handleSubmitButton}>
        <HeaderStyeld>แก้ไขข้อมูล</HeaderStyeld>
        <InputContainer>
          <InputRow>
            <CategoryLabel>Username</CategoryLabel>
            <Input
              value={form.username}
              required
              name="username"
              onChange={(e) => handleOnChangeInput(e)}
            />
          </InputRow>
          <InputRow>
            <CategoryLabel>Role</CategoryLabel>
            <Dropdown
              list={RoleConstants}
              value={form.role}
              onChange={(value: string) => setForm({ ...form, role: value })}
            />
          </InputRow>
        </InputContainer>
        <ButtonContainer>
          <Button mode="cancel" type="button" onClick={() => handleShow(!show)}>
            ยกเลิก
          </Button>
          <Button mode="primary" type="submit">
            บันทึก
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

export default EditUserModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.form`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 35%;
  padding: 16px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (min-width: 767px) {
    width: 345px;
  }
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
`;

const CategoryLabel = styled.label`
  font-size: 14px;
  line-height: 34px;
  color: #949494;
  white-space: nowrap;
  margin-right: 20px;
  width: 100px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
`;

const InputRow = styled.div`
  display: inline-flex;
  margin: 0 0 8px 0;
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;
