import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ic_down from "../../assets/icons/ic_down.svg";
import useOnClickOutside from "../../utils/useOnClickOutside";

export interface DropdownContainerInterface {
  showDropdown: boolean;
}

export interface DropdownItemInterface {
  selected?: boolean;
}

export interface DropdownInterface {
  list: ListItemInterface[];
  value?: string;
  onChange: Function;
}

export interface ListItemInterface {
  id: string;
  value: string;
  [key: string]: any;
}

const Dropdown = (props: DropdownInterface) => {
  const { list, onChange, value } = props;
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [choice, setChoice] = useState(list[0].value);

  useEffect(() => {
    if (value) {
      const idx = list.findIndex((item) => item.id === value);
      if (list[idx]) {
        onChange(list[idx].id);
        setChoice(list[idx].value);
      }
    } else {
      onChange(list[0].id);
    }
  }, []);

  const handleClickChoice = (id: string, value: string) => {
    setChoice(value);
    setShowDropdown(false);
    onChange(id);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  return (
    <ButtonDropdown>
      <ButtonStyled
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {choice}
        <Icon src={ic_down}></Icon>
      </ButtonStyled>
      <DropdownContainer showDropdown={showDropdown} ref={dropdownRef}>
        {list.map(({ id, value }) => (
          <DropdownItem
            key={id}
            onClick={() => handleClickChoice(id, value)}
            selected={choice === value}
          >
            {value}
          </DropdownItem>
        ))}
      </DropdownContainer>
    </ButtonDropdown>
  );
};

export default Dropdown;

const ButtonDropdown = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonStyled = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 10px 16px 8px 16px;
  border-radius: 4px;
  background: #f37021;
  color: #fff;
  cursor: pointer;
`;

const Icon = styled.img`
  padding: 0 0 0 2px;
`;

const DropdownContainer = styled.div<DropdownContainerInterface>`
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 9;
  background: #f37021;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  padding: 4px 0 4px 0;

  display: ${(props) => (props.showDropdown ? "" : "none")};
`;

const DropdownItem = styled.div<DropdownItemInterface>`
  padding: 8px 0px 8px 16px;
  white-space: nowrap;
  color: #fff;
  color: ${(props) => (props.selected ? "#f37021" : "#fff")};
  background-color: ${(props) => (props.selected ? "#fff" : "#f37021")};

  &:hover {
    color: #f37021;
    background-color: #fff;
  }
`;
