import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { OrangeInput } from "../../component/input/Input";
import { FullButton, CancelButton } from "../../component/button/Button";
import ic_logo_md from "../../assets/icons/ic_logo_md.svg";
import { LoginBody, register } from "../../redux/services/auth";
import { useAlert } from "react-alert";
import { AuthSuccess } from "../../redux/action/auth";
import { useDispatch } from "react-redux";
import { Path } from "../../constants/path";
import { AlertText } from "../../constants/alertText";
import { BLOCK_SCRIPT_REGEX } from "../../constants/data";

interface RouteParams {
  location: {
    search: string;
  };
}
interface RegisterProps extends RouteComponentProps {}

const defaultForm: LoginBody = {
  username: "",
  password: "",
};

const Register: React.FC<RegisterProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const params = new URLSearchParams(props.location.search);
  const token = params.get("registerToken") || "";
  const [form, setForm] = useState<LoginBody>(defaultForm);
  const [rePassword, setRePassword] = useState("");

  const handleRegisterButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!token) return alert.error("Token ผิดพลาด");
    if (form.password !== rePassword)
      return alert.error(AlertText.mismatchPassword);

    const response = await register(token, form);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      alert.success("ลงชื่อเข้าใช้สำเร็จ");
      dispatch(AuthSuccess(response.tokens, response.user));
      history.push(Path.home);
    }
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={handleRegisterButton}>
      <LogoContainer>
        <Logo src={ic_logo_md} />
      </LogoContainer>
      <ContentContainter>
        <InputContainter>
          <OrangeInput placeholder="Token" disabled={true} value={token} />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            placeholder="ชื่อผู้ใช้งาน"
            required
            name="username"
            value={form.username}
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            type="password"
            placeholder="รหัสผ่าน"
            required
            name="password"
            value={form.password}
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            placeholder="ยืนยันรหัสผ่าน"
            type="password"
            required
            name="rePassword"
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
          />
        </InputContainter>
        <InputContainter>
          <FullButton type="submit">ลงชื่อเข้าใช้</FullButton>
          <CancelButton type="button" onClick={() => history.push("/login")}>
            ย้อนกลับ
          </CancelButton>
        </InputContainter>
      </ContentContainter>
    </form>
  );
};

export default Register;

const InputContainter = styled.div`
  margin-bottom: 16px;
`;

const ContentContainter = styled.div`
  padding: 0 40px 0 40px;
`;

const Logo = styled.img`
  margin: 0 0 64px 0;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
