import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
  OrangeButtonWithIcon,
  OrangeLeftIcon,
  OrangeRightIcon,
} from "../component/button/OrangeButton";
import ic_down from "../assets/icons/ic_down.svg";
import ic_circle_add from "../assets/icons/ic_circle_add.svg";
import ic_info from "../assets/icons/ic_info.svg";
import User from "../component/User";
import {
  DropdownContainerInterface,
  DropdownItemInterface,
} from "../component/input/Dropdown";
import useOnClickOutside from "../utils/useOnClickOutside";
import { CreateUser } from "../constants/data";
import NewUserManualModal from "../component/modal/NewUserManualModal";
import NewUserUrlModal from "../component/modal/NewUserUrlModal";
import { getUnusedUser, getUser } from "../redux/services/auth";
import {
  RegisterTokenResponse,
  UserResponse,
} from "../constants/responseTypes";
import UnusedUser from "../component/UnusedUser";
import { useAlert } from "react-alert";
import RoleConfigModal from "../component/modal/RoleConfigModal";

enum ModalState {
  Link = "link",
  Manual = "manual",
  Role = "role",
}

const UserManagement = () => {
  const dropdownRef = useRef(null);
  const alert = useAlert();
  const [showDropdown, setShowDropdown] = useState(false);
  const [modal, setModal] = useState<string>();
  const [unusedListUser, setUnusedListUser] = useState<RegisterTokenResponse[]>(
    []
  );
  const [userList, setUserList] = useState<UserResponse[]>([]);

  const handleClickChoice = (link: string) => {
    setShowDropdown(false);
    setModal(link);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  useEffect(() => {
    getListUnusedUser();
    getListUser();
  }, []);

  const getListUnusedUser = async () => {
    const response = await getUnusedUser();
    if (typeof response === "string") {
      alert.error(response);
    } else {
      setUnusedListUser(response);
    }
  };

  const getListUser = async () => {
    const response = await getUser();
    if (typeof response === "string") {
      alert.error(response);
    } else {
      setUserList(response.results);
    }
  };

  return (
    <>
      <div>
        <HeaderContainer>
          <HeaderLeft>
            <HeaderText>จัดการผู้ใช้งาน</HeaderText>
            <div>
              <OrangeButtonWithIcon
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <OrangeLeftIcon src={ic_circle_add} />
                เพิ่มผู้ใช้งาน
                <OrangeRightIcon src={ic_down} />
              </OrangeButtonWithIcon>
              <DropdownContainer showDropdown={showDropdown} ref={dropdownRef}>
                {CreateUser.map((item, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleClickChoice(item.link)}
                  >
                    {item.name}
                  </DropdownItem>
                ))}
              </DropdownContainer>
            </div>
          </HeaderLeft>
          <OrangeButtonWithIcon
            onClick={() => handleClickChoice(ModalState.Role)}
          >
            Role
            <OrangeRightIcon src={ic_info} />
          </OrangeButtonWithIcon>
        </HeaderContainer>
        <ContentContainer>
          {userList.map((user: UserResponse, index) => (
            <User key={index} {...user} trigger={() => getListUser()}></User>
          ))}
          {unusedListUser.map((user: RegisterTokenResponse, index) => (
            <UnusedUser
              key={index}
              {...user}
              trigger={() => getListUnusedUser()}
            ></UnusedUser>
          ))}
        </ContentContainer>
      </div>
      <NewUserManualModal
        show={modal === ModalState.Manual}
        handleShow={(value: ModalState) => setModal(value)}
        trigger={() => getListUser()}
      />
      <NewUserUrlModal
        show={modal === ModalState.Link}
        handleShow={(value: ModalState) => setModal(value)}
        trigger={() => getListUnusedUser()}
      />
      <RoleConfigModal
        show={modal === ModalState.Role}
        handleShow={(value: ModalState) => setModal(value)}
      />
    </>
  );
};

export default UserManagement;

const HeaderText = styled.label`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 10px 8px 0;
  white-space: nowrap;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  margin-top: 16px;
`;

const DropdownContainer = styled.div<DropdownContainerInterface>`
  position: absolute;
  z-index: 9;
  width: 100px;
  background: #f37021;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  padding: 4px 0 4px 0;

  display: ${(props) => (props.showDropdown ? "" : "none")};
`;

const DropdownItem = styled.div<DropdownItemInterface>`
  padding: 8px 0px 8px 16px;
  white-space: nowrap;
  color: #fff;

  &:hover {
    color: #686868;
    background-color: #fff;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;
