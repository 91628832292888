import styled from "styled-components";
interface ButtonInterface {
  mode: string;
}

export enum ButtonMode {
  Primary = "primary",
  Secondary = "secondary",
  Cancel = "cancel",
}

export const Button = styled.button<ButtonInterface>`
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  background: ${(props) => props.mode === ButtonMode.Primary && "#f37021"}
    ${(props) => props.mode === ButtonMode.Secondary && "#949494"}
    ${(props) => props.mode === ButtonMode.Cancel && "#fff"};
  border-radius: 4px;
  padding: 6px 16px 6px 16px;
  border-style: none;
  color: ${(props) => props.mode === ButtonMode.Primary && "#fff"}
    ${(props) => props.mode === ButtonMode.Secondary && "#fff"}
    ${(props) => props.mode === ButtonMode.Cancel && "#000"};
  margin-left: 5px;
  white-space: nowrap;
`;

export const FullButton = styled.button`
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  padding: 10px 0 10px 0;
  border-style: none;
  background: #f37021;
  color: #fff;
  width: 100%;
  margin-top: 16px;
`;

export const CancelButton = styled.button`
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  padding: 10px 0 10px 0;
  border-style: none;
  background: transparent;
  color: #fff;
  width: 100%;
  margin-top: 16px;
`;
