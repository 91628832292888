import { CategoryResponse } from "../services/category";
import { QaResponse } from "../services/qa";

export const getCurrentCategory = (state: any) => {
  return state.category?.current as CategoryResponse;
};

export const getCurrentPath = (state: any) => {
  return state.category?.path as CategoryResponse[];
};

export const getLastestQa = (state: any) => {
  return state.qa?.lastestQa as QaResponse;
};

export const getQaCardList = (state: any) => {
  return state.qa?.qaList as QaResponse[];
};

export const getSelectedCard = (state: any) => {
  return state.qa?.selected as QaResponse;
};
