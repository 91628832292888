import { TokenResponse, UserResponse } from "../../constants/responseTypes";

export const AuthSuccess = (token: TokenResponse, user: UserResponse) => ({
  type: "AUTH_SUCCESS",
  payload: {
    token,
    user,
  },
});

export const SessionTimeout = () => ({
  type: "SESSION_TIMEOUT",
});

export const setLoading = (bool: boolean) => ({
  type: "LOADING",
  payload: {
    loading: bool,
  },
});
