import { ActionWithPayload } from "../../constants/types";
import * as Types from "../constants/types";
import { CategoryResponse } from "../services/category";

export interface CategoryPayload {
  current?: CategoryResponse;
  path: CategoryResponse[];
}

export const initialCategory = {
  name: "หน้าแรก",
  path: "/",
  parent: "/",
  id: "0",
};

export const categoryReducer = (
  state = { current: initialCategory, path: [initialCategory] },
  action: ActionWithPayload<CategoryPayload>
): CategoryPayload => {
  switch (action.type) {
    case Types.SET_CURRENT_PATH:
      return {
        ...state,
        current: action.payload?.current,
        path: action.payload?.current
          ? [...state.path, action.payload?.current]
          : [...state.path],
      };
    case Types.SELECT_CURRENT_CATEGORY:
      const currentIndex = state.path.findIndex(
        (category) => category === action.payload?.current
      );
      const newPath =
        currentIndex !== -1
          ? state.path.slice(0, currentIndex + 1)
          : state.path;
      return {
        ...state,
        current: action.payload?.current,
        path: newPath,
      };
    case Types.DELETE_CURRENT_CATEGORY:
      const poppedPath = state.path;
      const poppedItem = poppedPath.pop();
      return {
        ...state,
        current: poppedPath[poppedPath.length - 1],
        path: poppedPath,
      };
    case Types.UPDATE_CURRENT_CATEGORY:
      return {
        ...state,
        current: action.payload?.current,
        path: state.path.map((category) =>
          category.id === action.payload?.current?.id
            ? action.payload?.current
            : category
        ),
      };
  }
  return state;
};
