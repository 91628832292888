import React, { FormEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Input, TextArea } from "../input/Input";
import { Button } from "../button/Button";
import { ModalInterface } from "../../constants/types";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCategory } from "../../redux/selectors/path";
import {
  createQa,
  CreateQaRequest,
  QaResponse,
  updateQa,
} from "../../redux/services/qa";
import { AlertText } from "../../constants/alertText";
import { createQaCard, updateQaCard } from "../../redux/action/qa";
import { BLOCK_SCRIPT_REGEX } from "../../constants/data";
import { setLoading } from "../../redux/action/auth";

interface QuestionModalInterface {
  show: boolean;
  handleShow: Function;
  editMode: boolean;
  detail?: QaResponse;
}

const defaultForm = {
  files: [],
  question: "",
  answer: "",
  category: "",
};

const QuestionModal = (props: QuestionModalInterface) => {
  const { show, handleShow, editMode, detail } = props;
  const dispatch = useDispatch();
  const currentCategory = useSelector((state) => getCurrentCategory(state));
  const alert = useAlert();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [form, setForm] = useState<CreateQaRequest>(defaultForm);

  useEffect(() => {
    setForm(
      editMode
        ? {
            question: detail?.question,
            answer: detail?.answer,
            files: detail?.files,
          }
        : defaultForm
    );
  }, [show]);

  const handleSubmitButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLoading(true));
    const response = await createQa({ ...form, category: currentCategory.id });
    if (typeof response === "string") {
      alert.error(response);
    } else {
      handleShow(false);
      dispatch(createQaCard(response));
      alert.success(AlertText.createQaSuccess);
    }
    dispatch(setLoading(false));
  };

  const handleEditButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(setLoading(true));
    if (!detail?.id) return;
    const response = await updateQa(detail?.id, form);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      handleShow(false);
      dispatch(updateQaCard(response));
      alert.success(AlertText.updateQaSuccess);
    }
    dispatch(setLoading(false));
  };

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = form.files ? [...form.files] : [];
    if (e.target.files) {
      fileList.push(e.target.files[0]);
      setForm({ ...form, files: fileList });
    }
  };

  const handleClickUpload = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <ModalContainer show={show}>
      <Container onSubmit={editMode ? handleEditButton : handleSubmitButton}>
        <HeaderStyeld>{editMode ? "แก้ไขคำถาม" : "เพิ่มคำถาม"}</HeaderStyeld>
        <InputContainer>
          <CategoryLabel>คำถาม</CategoryLabel>
          <Input
            required
            name="question"
            value={form.question}
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainer>
        <InputContainer>
          <CategoryLabel>ไฟล์</CategoryLabel>
          <input
            type="file"
            hidden
            ref={hiddenFileInput}
            accept="image/png, image/jpeg, image/jpg, video/*"
            onChange={(e) => handleInputFile(e)}
          />
          <FileContainer>
            {form.files?.map((file, index) => (
              <FileName key={index}>
                {index + 1}.{" "}
                {typeof file === "string" ? file : file && file.name}
              </FileName>
            ))}
            {form.files && form.files.length >= 10 ? null : (
              <>
                <AddFileButton onClick={() => handleClickUpload()}>
                  + เพิ่มไฟล์
                </AddFileButton>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#686868",
                    margin: "5px 0",
                  }}
                >
                  รองรับไฟล์รูปภาพ .jpg .jpeg .png
                  <br />
                  รองรับไฟล์วีดีโอ .mp4 .mov .wmv .avi .flv .mkv
                  <br />
                  ขนาดไฟล์สูงสุด 100 MB
                </label>
              </>
            )}
          </FileContainer>
        </InputContainer>
        <InputContainer>
          <CategoryLabel>คำตอบ</CategoryLabel>
          <TextArea
            rows={10}
            required
            name="answer"
            value={form.answer}
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainer>
        <ButtonContainer>
          <Button mode="cancel" type="button" onClick={() => handleShow(!show)}>
            ยกเลิก
          </Button>
          <Button mode="primary" type="submit">
            บันทึก
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

export default QuestionModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.form`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 10%;
  padding: 16px;
  border: 1px solid #888;
  width: 70%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (min-width: 767px) {
    width: 345px;
  }
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
`;

const CategoryLabel = styled.label`
  font-size: 14px;
  line-height: 34px;
  color: #949494;
  white-space: nowrap;
  margin-right: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 8px 0 8px 0;
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;

const FileContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
`;

const FileName = styled.label`
  margin-top: 8px;
`;

const AddFileButton = styled.label`
  color: #f37021;
  margin-top: 8px;
`;
