import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { categoryReducer } from "./categoryReducer";
import { qaReducer } from "./qaReducer";

const rootReducers = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  qa: qaReducer,
});

export default rootReducers;
