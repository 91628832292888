import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ic_expand from "../assets/icons/ic_expand.svg";
import ic_copy from "../assets/icons/ic_copy.svg";
import useOnClickOutside from "../utils/useOnClickOutside";
import {
  DropdownContainerInterface,
  DropdownItemInterface,
} from "./input/Dropdown";
import ic_three_dots_white from "../assets/icons/ic_three_dots_white.svg";
import ic_video from "../assets/icons/ic_video.svg";
import {
  QaCardDropdown,
  QaCardDropdownKey,
  URL_REGEX,
} from "../constants/data";
import DeleteModal from "./modal/DeleteModal";
import { ButtonMode } from "./button/Button";
import QuestionModal from "./modal/QuestionModal";
import { deleteQa, getListQa, QaResponse } from "../redux/services/qa";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { AlertText } from "../constants/alertText";
import { setLoading } from "../redux/action/auth";
import { deleteQaCard, setQaCardList } from "../redux/action/qa";
import { isRoleEditor } from "../redux/selectors/isRole";
interface ExpandIcon {
  isExpand: boolean;
}

interface QaProps {
  detail: QaResponse;
  selected: boolean | undefined;
}

const QaCard = (props: QaProps) => {
  const { detail, selected } = props;
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isExpand, setIsExpand] = useState(
    selected !== undefined ? selected : false
  );
  const [showDropdown, setShowDropdown] = useState(false);
  const isEditor = useSelector((state) => isRoleEditor(state));
  const [modal, setModal] = useState<QaCardDropdownKey>();

  useEffect(() => {
    if (selected !== undefined) {
      setIsExpand(selected);
    }
  }, [selected]);

  const handleClickChoice = (choice: QaCardDropdownKey) => {
    setModal(choice);
    setShowDropdown(false);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  const handleDeleteCategory = async () => {
    dispatch(setLoading(true));
    const response = await deleteQa(detail.id);
    if (typeof response === "string" && response !== "") {
      alert.error(response);
    } else {
      alert.success(AlertText.deleteQaSuccess);
      dispatch(deleteQaCard());
      fetchQaCard(detail.category);
      setModal(undefined);
    }
    dispatch(setLoading(false));
  };

  const fetchQaCard = async (categoryId: string) => {
    dispatch(setLoading(true));
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      dispatch(setQaCardList(response.results));
    }
    dispatch(setLoading(false));
  };

  const urlReplace = (text: string) => {
    return text.replace(URL_REGEX, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  };

  return (
    <>
      <Container>
        <HeaderContainer isExpand={isExpand}>
          <ThreedotContainer>
            <QuestionHeader>{detail.question}</QuestionHeader>
            {isEditor ? (
              <ThreeDotsContainer>
                <ThreeDotsStyled
                  src={ic_three_dots_white}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
                <DropdownContainer
                  showDropdown={showDropdown}
                  ref={dropdownRef}
                >
                  {QaCardDropdown.map((item, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => handleClickChoice(item)}
                    >
                      {item}
                    </DropdownItem>
                  ))}
                </DropdownContainer>
              </ThreeDotsContainer>
            ) : null}
          </ThreedotContainer>
          <ExpandIcon
            src={ic_expand}
            onClick={() => setIsExpand(!isExpand)}
            isExpand={isExpand}
          />
        </HeaderContainer>
        <ExpandContainer isExpand={isExpand}>
          <SubExpandContainer>
            <ImageContainer>
              {detail.files.map((file, index) => (
                <>
                  {file.includes("mp4") || file.includes("MP4") ? (
                    <VideoStyled
                      src={`${process.env.REACT_APP_BACKEND_URL}/${file}#t=0.001`}
                      preload="metadata"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_BACKEND_URL}/${file}`,
                          "_self"
                        )
                      }
                    />
                  ) : (
                    <ImageStyled
                      src={`${process.env.REACT_APP_BACKEND_URL}/${file}`}
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_BACKEND_URL}/${file}`,
                          "_self"
                        )
                      }
                    />
                  )}
                </>
              ))}
            </ImageContainer>
            <AnswerContainer>
              <div>
                <AnswerText>
                  <DangerAnswerText
                    dangerouslySetInnerHTML={{
                      __html: urlReplace(detail.answer),
                    }}
                  />
                </AnswerText>
              </div>
              <CopyIcon
                src={ic_copy}
                onClick={() => {
                  navigator.clipboard.writeText(detail.answer);
                  alert.success(AlertText.copySuccess);
                }}
              />
            </AnswerContainer>
            <UpdatedByText>อัปเดตโดย: {detail.updatedBy}</UpdatedByText>
          </SubExpandContainer>
        </ExpandContainer>
        <DeleteModal
          header={"ลบคำถาม"}
          content={
            "การลบคำถามจะทำให้ข้อมูลคำถาม คำตอบ และไฟล์ที่เพิ่มหายไปยืนยันการลบคำถามใช่หรือไม่ ?"
          }
          rightButton={{ label: "ลบคำถาม", mode: ButtonMode.Primary }}
          leftButton={{ label: "ยกเลิก", mode: ButtonMode.Secondary }}
          show={modal === QaCardDropdownKey.Delete}
          handleShow={(value: QaCardDropdownKey) => setModal(value)}
          handleRightButton={() => handleDeleteCategory()}
        />
        <QuestionModal
          show={modal === QaCardDropdownKey.Edit}
          handleShow={(value: QaCardDropdownKey) => setModal(value)}
          editMode={true}
          detail={detail}
        />
      </Container>
    </>
  );
};

export default QaCard;

const Container = styled.div`
  margin-bottom: 8px;
`;

const ExpandContainer = styled.div<ExpandIcon>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #686868;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: ${(props) => (props.isExpand ? "100vh" : "0")};
  transition: 0.4s ease-in-out;
`;

const SubExpandContainer = styled.div`
  display: grid;
  margin: 2px 8px 8px 8px;
`;

const HeaderText = styled.label`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0 8px 0;
`;

const QuestionHeader = styled.label`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
`;

const HeaderContainer = styled.div<ExpandIcon>`
  display: flex;
  justify-content: space-between;
  background-color: #686868;
  padding: 8px 10px 8px 10px;
  border-bottom-left-radius: ${(props) => (props.isExpand ? "0" : "4px")};
  border-bottom-right-radius: ${(props) => (props.isExpand ? "0" : "4px")};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ExpandIcon = styled.img<ExpandIcon>`
  transition: transform 200ms;
  cursor: pointer;
  transform: rotate(${(props) => (props.isExpand ? "0" : "180deg")});
`;

const AnswerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #949494;
  border-radius: 4px;
  padding: 8px;
  max-height: 200px;
`;

const AnswerText = styled.label`
  font-size: 14px;
  line-height: 23px;
  color: #fff;
  white-space: break-spaces;
`;

const UpdatedByText = styled.label`
  color: #c1c1c1;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  text-align: end;
`;

const CopyIcon = styled.img`
  margin-left: 10px;
  cursor: pointer;
`;

const ImageStyled = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
`;

const VideoStyled = styled.video`
  height: 50px;
  width: 50px;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  object-fit: fill;
  background-color: #000;
  background-size: contain;
`;

// background-image: url(${ic_video});

const ImageContainer = styled.div`
  margin-bottom: 10px;
`;

const DropdownContainer = styled.div<DropdownContainerInterface>`
  display: ${(props) => (props.showDropdown ? "" : "none")};
  position: absolute;
  z-index: 9;
  width: 116px;
  background: #f37021;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  padding: 4px 0 4px 0;
`;

const DropdownItem = styled.div<DropdownItemInterface>`
  padding: 8px 0px 8px 16px;
  white-space: nowrap;
  color: #fff;

  &:hover {
    color: #686868;
    background-color: #fff;
  }
`;

const ThreeDotsStyled = styled.img`
  cursor: pointer;
  color: #fff;
  padding-left: 10px;
`;

const ThreeDotsContainer = styled.div`
  margin-left: 8px;
  position: relative;
`;

const ThreedotContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DangerAnswerText = styled.div`
  overflow: auto;
  max-height: 200px;
`;
