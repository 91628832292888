import styled from "styled-components";

export const OrangeButtonWithIcon = styled.button`
  align-items: center;
  display: inline-flex;
  padding: 4px;
  background: #f37021;
  border-style: none;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
`;

export const OrangeLeftIcon = styled.img`
  padding-right: 4px;
`;

export const OrangeRightIcon = styled.img`
  padding-left: 4px;
`;
