import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { Input } from "../input/Input";
import { Button } from "../button/Button";
import Dropdown from "../input/Dropdown";
import { RoleConstants, BLOCK_SCRIPT_REGEX } from "../../constants/data";
import { createUser, UserBody } from "../../redux/services/auth";
import { useAlert } from "react-alert";
import { AlertText } from "../../constants/alertText";
import { Trigger } from "../../constants/types";

interface NewUserManualModalInterface {
  show: boolean;
  handleShow: Function;
}

interface ModalInterface {
  show: boolean;
}

interface CreateUserProps {
  username: string;
  password: string;
  re_password: string;
  role: string;
}

const defaultValue = {
  username: "",
  password: "",
  re_password: "",
  role: "super",
};

const NewUserManualModal = (props: NewUserManualModalInterface & Trigger) => {
  const { show, handleShow, trigger } = props;
  const alert = useAlert();
  const [form, setForm] = useState<CreateUserProps>(defaultValue);

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmitButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.password !== form.re_password)
      return alert.error(AlertText.mismatchPassword);

    const response = await createUser({
      username: form.username,
      password: form.password,
      role: form.role,
    });
    if (typeof response === "string") {
      alert.error(response);
    } else {
      handleShow(false);
      setForm(defaultValue);
      alert.success(AlertText.createUserSuccess);
      trigger();
    }
  };

  return (
    <ModalContainer show={show}>
      <Container onSubmit={handleSubmitButton}>
        <HeaderStyeld>เพิ่มผู้ใช้งานใหม่</HeaderStyeld>
        <InputContainer>
          <InputRow>
            <CategoryLabel>Username</CategoryLabel>
            <Input
              required
              name="username"
              value={form.username}
              onChange={(e) => handleOnChangeInput(e)}
            />
          </InputRow>
          <InputRow>
            <CategoryLabel>Password</CategoryLabel>
            <Input
              type="password"
              required
              name="password"
              value={form.password}
              onChange={(e) => handleOnChangeInput(e)}
            />
          </InputRow>
          <InputRow>
            <CategoryLabel>Re Password</CategoryLabel>
            <Input
              type="password"
              required
              name="re_password"
              value={form.re_password}
              onChange={(e) => handleOnChangeInput(e)}
            />
          </InputRow>
          <InputRow>
            <CategoryLabel>Role</CategoryLabel>
            <Dropdown
              list={RoleConstants}
              onChange={(value: string) => setForm({ ...form, role: value })}
            />
          </InputRow>
        </InputContainer>
        <ButtonContainer>
          <Button mode="cancel" onClick={() => handleShow(!show)}>
            ยกเลิก
          </Button>
          <Button mode="primary" type="submit">
            บันทึก
          </Button>
        </ButtonContainer>
      </Container>
    </ModalContainer>
  );
};

export default NewUserManualModal;

const ModalContainer = styled.div<ModalInterface>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`;

const Container = styled.form`
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;
  top: 30%;
  padding: 16px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  @media (min-width: 767px) {
    width: 345px;
  }
`;

const HeaderStyeld = styled.label`
  font-size: 16px;
  line-height: 26px;
  color: #232323;
`;

const CategoryLabel = styled.label`
  font-size: 14px;
  line-height: 34px;
  color: #949494;
  white-space: nowrap;
  margin-right: 10px;
  width: 120px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
`;

const InputRow = styled.div`
  display: inline-flex;
  margin: 0 0 8px 0;
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
`;
