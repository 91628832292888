export enum AlertText {
  mismatchPassword = "รหัสผ่านไม่ตรงกัน",
  copySuccess = "คัดลอกสำเร็จ",

  deleteUnusedUserSuccess = "ลบลิงก์สมัครสำเร็จ",

  createUserSuccess = "สร้างบัญชีผู้ใช้งานสำเร็จ",
  deleteUserSuccess = "ลบบัญชีผู้ใช้งานสำเร็จ",
  updateUserSuccess = "แก้ไขบัญชีผู้ใช้งานสำเร็จ",

  createCategorySuccess = "สร้างหัวข้อสำเร็จ",
  deleteCategorySuccess = "ลบหัวข้อสำเร็จ",
  updateCategorySuccess = "แก้ไขหัวข้อสำเร็จ",

  createQaSuccess = "สร้างคำถามสำเร็จ",
  deleteQaSuccess = "ลบคำถามสำเร็จ",
  updateQaSuccess = "แก้ไขคำถามสำเร็จ",
}
