import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { OrangeInput } from "../../component/input/Input";
import { FullButton } from "../../component/button/Button";
import ic_logo_md from "../../assets/icons/ic_logo_md.svg";
import { AuthSuccess } from "../../redux/action/auth";
import { Path } from "../../constants/path";
import { FormEvent, useEffect, useState } from "react";
import { login, LoginBody } from "../../redux/services/auth";
import { useAlert } from "react-alert";
import { getListCategory } from "../../redux/services/category";
import { BLOCK_SCRIPT_REGEX } from "../../constants/data";
import getToken from "../../redux/selectors/getToken";

const defaultForm: LoginBody = {
  username: "",
  password: "",
};

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [form, setForm] = useState<LoginBody>(defaultForm);
  const hasToken = useSelector((state) => getToken(state));

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    if (hasToken) {
      history.push(Path.home);
    }
  };

  const handleSubmitButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await login(form);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      alert.success("ลงชื่อเข้าใช้สำเร็จ");
      dispatch(AuthSuccess(response.tokens, response.user));
      history.push(Path.home);
    }
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!BLOCK_SCRIPT_REGEX.test(e.target.value))
      setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <LogoContainer>
        <Logo src={ic_logo_md} />
      </LogoContainer>
      <ContentContainter onSubmit={handleSubmitButton}>
        <InputContainter>
          <OrangeInput
            placeholder="ชื่อผู้ใช้งาน"
            required
            name="username"
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainter>
        <InputContainter>
          <OrangeInput
            type="password"
            placeholder="รหัสผ่าน"
            required
            name="password"
            onChange={(e) => handleOnChangeInput(e)}
          />
        </InputContainter>
        <InputContainter>
          <FullButton type="submit">ลงชื่อเข้าใช้</FullButton>
        </InputContainter>
      </ContentContainter>
      {/* TODO: version */}
      <VersionStyled>version 1.0.3</VersionStyled>
    </div>
  );
};

export default Login;

const InputContainter = styled.div`
  margin-bottom: 16px;
`;

const ContentContainter = styled.form`
  padding: 0 40px 0 40px;
`;

const Logo = styled.img`
  margin: 0 0 64px 0;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const VersionStyled = styled.label`
  position: fixed;
  bottom: 0;
  right: 5px;
  margin: 0;
  font-size: 10px;
  color: #292829;
`;
