import axios, { AxiosResponse } from "axios";
import { PaginationProps } from "../../constants/types";
import { getConfig, handleErrorResponse } from "./auth";
import { CategoryResponse } from "./category";

const API_VERSION = "v1";
const SECTION = "qa";
const BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL}/${API_VERSION}/${SECTION}`;
const LIMIT_PER_PAGE = "&limit=200";

export interface CreateQaRequest {
  files?: (string | File)[];
  question?: string;
  answer?: string;
  category?: string;
}

export interface CreateQaResponse {
  files: string[];
  question: string;
  answer: string;
  category: string;
  id: string;
}

export interface QaResponse extends CreateQaResponse {
  createdBy: string;
  updatedBy: string;
  isExpand?: boolean;
}

export interface SearchBarResponse {
  files: string[];
  question: string;
  answer: string;
  category: CategoryResponse;
  id: string;
  createdBy: string;
  updatedBy: string;
}

export interface GetListQaResponse extends PaginationProps {
  results: QaResponse[];
}

export enum SearchChoice {
  QuestionOnly = "q",
  AnswerOnly = "a",
  QuestionAndAnswer = "qa",
}

const convertToFormData = (form: CreateQaRequest) => {
  const formdata = new FormData();
  if (form.files) {
    for (const file of form.files) {
      // URL.createObjectURL()
      if (typeof file !== "string") formdata.append("files", file, file.name);
    }
  }
  if (form.question) formdata.append("question", form.question);
  if (form.answer) formdata.append("answer", form.answer);
  if (form.category) formdata.append("category", form.category);
  return formdata;
};

export const createQa = async (form: CreateQaRequest) => {
  try {
    const response = await axios.post<FormData, AxiosResponse<QaResponse>>(
      `${BACKEND_URL}`,
      convertToFormData(form),
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getQaById = async (id: string) => {
  try {
    const response = await axios.get<QaResponse>(
      `${BACKEND_URL}/${id}`,
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const deleteQa = async (id: string) => {
  try {
    const response = await axios.delete(`${BACKEND_URL}/${id}`, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const updateQa = async (id: string, form: CreateQaRequest) => {
  try {
    const response = await axios.patch<FormData, AxiosResponse<QaResponse>>(
      `${BACKEND_URL}/${id}`,
      convertToFormData(form),
      getConfig()
    );
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const searchQa = async (
  category: string | undefined,
  searchBy: SearchChoice,
  searchText?: string
) => {
  try {
    const url = category
      ? `${BACKEND_URL}/search?searchBy=${searchBy}&searchText=${searchText}&category=${category}`
      : `${BACKEND_URL}/search?searchBy=${searchBy}&searchText=${searchText}`;
    const response = await axios.get<SearchBarResponse[]>(url, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};

export const getListQa = async (path?: string) => {
  const url = path
    ? `${BACKEND_URL}?${path}${LIMIT_PER_PAGE}`
    : `${BACKEND_URL}${LIMIT_PER_PAGE}`;

  try {
    const response = await axios.get<GetListQaResponse>(url, getConfig());
    return response.data;
  } catch (error: any) {
    return handleErrorResponse(error);
  }
};
