import { useState, useRef } from "react";
import styled from "styled-components";
import { OrangeButtonWithIcon } from "./button/OrangeButton";
import ic_three_dots from "../assets/icons/ic_three_dots.svg";
import {
  DropdownContainerInterface,
  DropdownItemInterface,
} from "./input/Dropdown";
import useOnClickOutside from "../utils/useOnClickOutside";
import { UserDropdownKey } from "../constants/data";
import DeleteModal from "./modal/DeleteModal";
import { ButtonMode } from "./button/Button";
import { RegisterTokenResponse } from "../constants/responseTypes";
import { Trigger } from "../constants/types";
import { getDateTimeFormat } from "../utils/dateTime";
import { deleteUnusedUser } from "../redux/services/auth";
import { useAlert } from "react-alert";
import { AlertText } from "../constants/alertText";
import { setLoading } from "../redux/action/auth";
import { useDispatch } from "react-redux";

const UnusedUser = (props: RegisterTokenResponse & Trigger) => {
  const { id, role, expires, trigger } = props;
  const dropdownRef = useRef(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [modal, setModal] = useState<UserDropdownKey>();

  const handleClickChoice = (choice: UserDropdownKey) => {
    setModal(choice);
    setShowDropdown(false);
  };

  const handleClickOutside = () => {
    setShowDropdown(false);
  };

  useOnClickOutside(dropdownRef, handleClickOutside);

  const deleteUrlLink = async () => {
    dispatch(setLoading(true));
    const response = await deleteUnusedUser(id);
    if (typeof response === "string" && response !== "") {
      alert.error(response);
    } else {
      alert.success(AlertText.deleteUnusedUserSuccess);
      trigger();
    }
    dispatch(setLoading(false));
  };

  const handleCopyUrl = async () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/register?registerToken=${id}`
    );
    alert.success("คัดลอกลิงก์สมัคร");
    setShowDropdown(false);
  };

  return (
    <>
      <UserContainter>
        <UsernameContainter>
          <UsernameStyled>{id}</UsernameStyled>
          <EmailStyled>{getDateTimeFormat(expires)}</EmailStyled>
        </UsernameContainter>
        <RoleContainer>
          <RoleBotton>{role.toLocaleUpperCase()}</RoleBotton>
          <RelativeBox>
            <ThreeDotsStyled
              src={ic_three_dots}
              onClick={() => setShowDropdown(!showDropdown)}
            />
            <DropdownContainer showDropdown={showDropdown} ref={dropdownRef}>
              <DropdownItem onClick={() => handleCopyUrl()}>
                คัดลอกลิงก์สมัคร
              </DropdownItem>
              <DropdownItem
                onClick={() => handleClickChoice(UserDropdownKey.Delete)}
              >
                ลบลิงก์สมัคร
              </DropdownItem>
            </DropdownContainer>
          </RelativeBox>
        </RoleContainer>
      </UserContainter>
      {/* <EditUserModal
        show={modal === UserDropdownKey.Edit}
        handleShow={(value: UserDropdownKey) => setModal(value)}
      /> */}
      {/* <ResetPassword
        user={{ username }}
        show={modal === UserDropdownKey.ChangePassword}
        handleShow={(value: UserDropdownKey) => setModal(value)}
      /> */}
      <DeleteModal
        header={"ลบลิงก์สมัคร"}
        content={"การลบลิงก์สมัคร จะทำให้ลิงก์สมัครนั้นไม่สามารถใช้งานได้อีก"}
        rightButton={{ label: "ลบลิงก์", mode: ButtonMode.Primary }}
        leftButton={{ label: "ยกเลิก", mode: ButtonMode.Secondary }}
        show={modal === UserDropdownKey.Delete}
        handleShow={(value: UserDropdownKey) => setModal(value)}
        handleRightButton={() => deleteUrlLink()}
      />
    </>
  );
};

export default UnusedUser;

const UserContainter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px 8px 10px;
`;

const UsernameContainter = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsernameStyled = styled.label`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: #232323;
`;

const EmailStyled = styled.label`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.28px;
  color: #949494;
`;

const RoleBotton = styled(OrangeButtonWithIcon)`
  width: 80px;
  justify-content: center;
  margin-right: 14px;
  cursor: initial;
`;

const RoleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ThreeDotsStyled = styled.img`
  cursor: pointer;
`;

const DropdownContainer = styled.div<DropdownContainerInterface>`
  display: ${(props) => (props.showDropdown ? "" : "none")};
  position: absolute;
  width: 130px;
  z-index: 9;
  background: rgba(104, 104, 104, 0.7);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(24px);
  border-radius: 4px;
  padding: 4px 0 4px 0;
  right: 0;
`;

const DropdownItem = styled.div<DropdownItemInterface>`
  padding: 8px 0px 8px 16px;
  white-space: nowrap;
  color: #fff;

  &:hover {
    color: #686868;
    background-color: #fff;
  }
`;

const RelativeBox = styled.div`
  position: relative;
`;
