import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import QaCard from "../component/QaCard";
import Breadcrumbs from "../component/Breadcrumbs";
import CategoryList from "../component/CategoryList";
import MainSearchDropdown from "../component/input/MainSearchDropdown";
import {
  OrangeButtonWithIcon,
  OrangeLeftIcon,
} from "../component/button/OrangeButton";
import ic_circle_add from "../assets/icons/ic_circle_add.svg";
import { QaCardDropdownKey } from "../constants/data";
import useOnClickOutside from "../utils/useOnClickOutside";
import QuestionModal from "../component/modal/QuestionModal";
import { getListQa } from "../redux/services/qa";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/action/auth";
import { setQaCardList } from "../redux/action/qa";
import {
  getCurrentCategory,
  getLastestQa,
  getQaCardList,
  getSelectedCard,
} from "../redux/selectors/path";
import { useAlert } from "react-alert";
import { isRoleEditor } from "../redux/selectors/isRole";

const FirstPage = () => {
  const dropdownRef = useRef(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  const lastestQa = useSelector((state) => getLastestQa(state));
  const isEditor = useSelector((state) => isRoleEditor(state));
  const currentCategory = useSelector((state) => getCurrentCategory(state));
  const selectedCard = useSelector((state) => getSelectedCard(state));
  const QaCardList = useSelector((state) => getQaCardList(state));
  const [showDropdown, setShowDropdown] = useState(false);
  const [modal, setModal] = useState<QaCardDropdownKey>();

  const handleClickOutside = () => {
    setShowDropdown(false);
  };
  useOnClickOutside(dropdownRef, handleClickOutside);

  useEffect(() => {
    if (currentCategory.name !== "หน้าแรก") {
      fetchQaCard(currentCategory.id);
    }
  }, [lastestQa]);

  useEffect(() => {}, [QaCardList]);

  const fetchQaCard = async (categoryId?: string) => {
    dispatch(setLoading(true));
    const response = await getListQa(`category=${categoryId}`);
    if (typeof response === "string") {
      alert.error(response);
    } else {
      dispatch(setQaCardList(response.results));
    }
    dispatch(setLoading(false));
  };

  return (
    <div>
      <MainSearchDropdown placeholder={"ค้นหา"} />
      <Breadcrumbs />
      <CategoryList />
      <div style={{ marginTop: "24px" }}>
        <HeaderContainer>
          <HeaderContainer>
            <HeaderText>คำถาม</HeaderText>
          </HeaderContainer>
          {currentCategory.name !== "หน้าแรก" && isEditor ? (
            <div>
              <OrangeButtonWithIcon
                onClick={() => setModal(QaCardDropdownKey.Create)}
              >
                <OrangeLeftIcon src={ic_circle_add} />
                เพิ่มคำถาม
              </OrangeButtonWithIcon>
            </div>
          ) : null}
        </HeaderContainer>
        <CardContainer>
          {(QaCardList.length === 1 && currentCategory.name === "หน้าแรก") ||
          QaCardList.length > 0 ? (
            QaCardList.map((item, index) => (
              <QaCard key={index} detail={item} selected={item.isExpand} />
            ))
          ) : (
            <EmptyStyled>ยังไม่มีคำถามที่จะแสดง</EmptyStyled>
          )}
        </CardContainer>
      </div>
      <QuestionModal
        show={modal === QaCardDropdownKey.Create}
        handleShow={(value: QaCardDropdownKey) => setModal(value)}
        editMode={false}
      />
    </div>
  );
};

export default FirstPage;

const HeaderText = styled.label`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0 8px 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const EmptyStyled = styled.label`
  color: #686868;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const AddButtonContainer = styled.div`
  padding: 5px 0 5px 5px;
`;
